<template>
  <div class="temporaries">
    <ul class="tight">
      <li class="border-hover-point" v-for="(item, idx) in state.items" :key="idx">
        <div class="wrapper">
          <div class="inner">
            <span class="sign latest mr-1" v-if="idx === 1">최근 작성</span>
            <span class="sign state" :data-state="item.regState" v-if="item.regState">{{ $definitions.codes.obj.projectStatus[item.regState] }}</span>
            <div class="title font-md bold">{{ item.projectName ? item.projectName : "(제목 없음)" }}</div>
            <div class="date font-sm" v-if="item.updateDate">{{ item.updateDate }}</div>
            <div class="act">
              <a class="btn btn-bordered-point font-xs" @click="$router.push({path: `/apply/${item.projectSeq}`})" v-if="item.projectSeq">이어서 신청</a>
              <a class="btn btn-bordered-point font-xs" @click="create()" v-else>새롭게 신청</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalTemporaries";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      http.get("/api/reward/projects/in-progress", args, {cache: true}).then(({data}) => {
        state.loaded = true;

        const items = data.body;

        items.splice(0, 0, {
          projectSeq: 0,
          projectName: "신규 프로젝트",
        });

        state.items = items;
      });
    });

    const state = reactive({
      loaded: false,
      items: []
    });

    const modalParams = store.getters.modalParams(component);

    const args = {
      partnershipFlag: modalParams?.partnershipFlag || "N",
      simulationFlag: modalParams?.simulationFlag || "N",
      contestSeq: modalParams?.contestSeq || null
    };

    const create = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    return {component, state, create};
  }
});
</script>

<style lang="scss" scoped>
.temporaries {
  ul {
    li {
      margin-bottom: $px20;

      .wrapper {
        background: #fff;
        border-radius: $px4;
        border: $px1 solid #eee;
        padding: $px25;

        .inner {
          position: relative;
          padding-right: $px130;

          .sign {
            display: inline-block;
            font-size: 70%;
            background: $colorSecondary;
            color: #fff;
            padding: $px1 $px5;
            margin-bottom: $px5;
            vertical-align: middle;
            border-radius: $px2;

            span {
              vertical-align: middle;
            }

            &.state[data-state=STATUS_0004] {
              background: $colorDanger;
            }

            &.latest {
              background: $colorPoint;
            }
          }

          .title {
            word-break: break-all;
            line-height: 1.2;
            padding: $px3 0;
          }

          .date {
            margin-top: $px5;
          }

          .delivery {
            color: #666;
            margin-top: $px15;
          }

          .act {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: $px-22;

            .btn {
              padding: $px12 $px25;
              border: $px1 solid;
            }
          }
        }

      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>